/**-----------------------------------------------------------------------------------------
* Copyright © 2020 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import { Injectable, Directive, ElementRef, Renderer2, Input, TemplateRef, EventEmitter, Component, ChangeDetectionStrategy, NgZone, HostBinding, ContentChild, ViewChild, ViewChildren, Output, ViewContainerRef, NgModule } from '@angular/core';
import { isDocumentAvailable, Keys, isChanged, EventsModule } from '@progress/kendo-angular-common';
import { Subject, Subscription } from 'rxjs';
import { PageSizeChangeEvent, PagerModule } from '@progress/kendo-angular-pager';
import { CommonModule } from '@angular/common';

/**
 * @hidden
 */
const isPresent = (item) => item !== null && item !== undefined;
/**
 * @hidden
 *
 * Polyfill for `Element.matches`.
 * https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
 */
const match = (element, selector) => {
    const matcher = element.matches || element.msMatchesSelector || element.webkitMatchesSelector;
    if (!isPresent(matcher)) {
        return false;
    }
    return matcher.call(element, selector);
};
/**
 * @hidden
 *
 * Checks if a target element has the `.k-listview-item` CSS class.
 */
const isListViewItem = (element) => {
    if (!isPresent(element)) {
        return false;
    }
    return match(element, '.k-listview-item');
};
/**
 * @hidden
 *
 * Extracts and parses to a number the `data-kendo-listview-item-index` attribute value from the targeted element.
 */
const getListItemIndex = (item) => {
    if (!isPresent(item)) {
        return null;
    }
    return Number(item.getAttribute('data-kendo-listview-item-index'));
};
/**
 * @hidden
 *
 * Gets the new focus target from a blur event.
 * Queries both event.relatedTarget and document.activeElement for compatibility with IE.
 */
const relatedTarget = (event) => {
    if (!isPresent(event.relatedTarget) || !isDocumentAvailable()) {
        return null;
    }
    return event.relatedTarget || document.activeElement;
};
/**
 * @hidden
 *
 * If the given contender number is not defined or lower than the specified min - returns min, if its above the specified max - returns max.
 * If the number is in the given bounds, it is returned.
 */
const fitIntoRange = (contender, min, max) => {
    if (!isPresent(contender) || contender <= min) {
        return min;
    }
    else if (contender >= max) {
        return max;
    }
    else {
        return contender;
    }
};
/**
 * @hidden
 */
const closestWithMatch = (element, selector) => {
    let parent = element;
    while (parent !== null && parent.nodeType === 1) {
        if (match(parent, selector)) {
            return parent;
        }
        parent = parent.parentElement || parent.parentNode;
    }
    return null;
};

/**
 * @hidden
 *
 * Provided per ListView instance. Keeps the availability, active index and focused state of the current ListView.
 * Emits `changes` when any of the aforementioned states changes.
 */
class NavigationService {
    constructor() {
        /**
         * Emits every time a change in active index/focus/blur/navigation availability occurs.
         */
        this.changes = new Subject();
        /**
         * Keeps track of the index of the items that should be the current focus target (tabindex="0").
         * When set to `null`/`undefined`, the navigation is disabled and the items should not render a tabindex.
         */
        this.activeIndex = null;
    }
    /**
     * Sets or gets if the navigation is enabled.
     * When no activeIndex is present, the navigation is inferred as disabled.
     * Toggleing the service availabiltiy clears the current active index or activates the first one.
     */
    get isEnabled() {
        return isPresent(this.activeIndex);
    }
    set isEnabled(enabled) {
        if (enabled) {
            this.activeIndex = 0;
        }
        else {
            this.activeIndex = null;
        }
        this.changes.next();
    }
    /**
     * Shows if the checked index should be the current available focus target (tabindex="0").
     */
    isActive(index) {
        return index === this.activeIndex;
    }
    handleKeyDown(event, totalItemsCount) {
        const { keyCode } = event;
        switch (keyCode) {
            case Keys.ArrowLeft:
            case Keys.ArrowUp:
                this.navigateToPrevious();
                break;
            case Keys.ArrowRight:
            case Keys.ArrowDown:
                this.navigateToNext(totalItemsCount);
                break;
            case Keys.Home:
                const firstIndex = 0;
                this.navigateTo(firstIndex);
                break;
            case Keys.End:
                const lastIndex = totalItemsCount - 1;
                this.navigateTo(lastIndex);
                break;
            default: return;
        }
        // the following line is executed only if the pressed key matches one of the listview hotkeys - they `break`, while the `default` case returns
        event.preventDefault();
    }
    handleFocusIn(event) {
        const target = event.target;
        if (!isListViewItem(target)) {
            const listViewItemSelector = '.k-listview-item';
            const closestListViewItem = closestWithMatch(target, listViewItemSelector);
            const isListViewItemChild = isPresent(closestListViewItem);
            if (isListViewItemChild) {
                const itemIndex = getListItemIndex(closestListViewItem);
                this.setActiveIndex(itemIndex);
            }
            return;
        }
        const targetIndex = getListItemIndex(target);
        // don't emit if the no change in focused state has occured and the targeted index is the same as the current activeIndex
        if (this.isFocused && targetIndex === this.activeIndex) {
            return;
        }
        this.activeIndex = targetIndex;
        this.isFocused = true;
        this.changes.next();
    }
    handleFocusOut(event) {
        // don't emit if the blurred item is not a listview item or if the new focus target is another listview item
        if (!isListViewItem(event.target) || isListViewItem(relatedTarget(event))) {
            return;
        }
        this.isFocused = false;
        this.changes.next();
    }
    /**
     * Sets the `activeIndex` and triggers changes without focusing the corresponding ListView item.
     */
    setActiveIndex(index) {
        if (!this.isEnabled) {
            return;
        }
        if (index === this.activeIndex) {
            return;
        }
        this.activeIndex = index;
        this.changes.next();
    }
    /**
     * Focuses item at the targeted index. If no index is passed, the current `activeIndex` is used.
     * The passed target index is normalized to fit the min/max available indices bounds.
     */
    focusIndex(index, totalItemsCount) {
        if (!this.isEnabled) {
            return;
        }
        const parsed = parseInt(index, 10);
        const firstIndex = 0;
        const lastIndex = totalItemsCount - 1;
        const targetIndex = isNaN(parsed) ? this.activeIndex : fitIntoRange(parsed, firstIndex, lastIndex);
        this.navigateTo(targetIndex);
    }
    navigateTo(index) {
        if (this.isFocused && this.activeIndex === index) {
            return;
        }
        this.isFocused = true;
        this.activeIndex = index;
        this.changes.next();
    }
    navigateToPrevious() {
        const previousIndex = Math.max(this.activeIndex - 1, 0);
        this.navigateTo(previousIndex);
    }
    navigateToNext(totalItemsCount) {
        const lastAvailableIndex = totalItemsCount - 1;
        const nextIndex = Math.min(this.activeIndex + 1, lastAvailableIndex);
        this.navigateTo(nextIndex);
    }
}
NavigationService.decorators = [
    { type: Injectable },
];

/**
 * @hidden
 */
class ListViewNavigableItemDirective {
    constructor(hostElement, renderer, navigationService) {
        this.hostElement = hostElement;
        this.renderer = renderer;
        this.navigationService = navigationService;
    }
    ngOnChanges() {
        this.updateNavigationState();
    }
    ngOnInit() {
        this.navigationSubscription = this.navigationService.changes
            .subscribe(this.updateNavigationState.bind(this));
    }
    ngOnDestroy() {
        if (isPresent(this.navigationSubscription)) {
            this.navigationSubscription.unsubscribe();
        }
    }
    updateNavigationState() {
        this.updateTabIndex();
        this.updateFocusedState();
    }
    updateFocusedState() {
        const shouldFocus = this.navigationService.isActive(this.index) && this.navigationService.isFocused;
        const focusedCssClass = 'k-state-focused';
        if (shouldFocus) {
            this.renderer.addClass(this.hostElement.nativeElement, focusedCssClass);
            this.hostElement.nativeElement.focus();
        }
        else {
            this.renderer.removeClass(this.hostElement.nativeElement, focusedCssClass);
        }
    }
    updateTabIndex() {
        if (!this.navigationService.isEnabled) {
            this.renderer.removeAttribute(this.hostElement.nativeElement, 'tabindex');
        }
        else if (this.navigationService.isActive(this.index)) {
            this.renderer.setAttribute(this.hostElement.nativeElement, 'tabindex', '0');
        }
        else {
            this.renderer.setAttribute(this.hostElement.nativeElement, 'tabindex', '-1');
        }
    }
}
ListViewNavigableItemDirective.decorators = [
    { type: Directive, args: [{
                selector: '[kendoListViewNavigableItem]'
            },] },
];
/** @nocollapse */
ListViewNavigableItemDirective.ctorParameters = () => [
    { type: ElementRef },
    { type: Renderer2 },
    { type: NavigationService }
];
ListViewNavigableItemDirective.propDecorators = {
    index: [{ type: Input }]
};

/**
 * Renders the list item content. To define the item template, nest an `<ng-template>` tag
 * with the `kendoListViewItemTemplate` directive inside the component tag. The template context is
 * set to the current component. To get a reference to the current data item, use the `let-dataItem` directive.
 */
class ItemTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
}
ItemTemplateDirective.decorators = [
    { type: Directive, args: [{
                selector: '[kendoListViewItemTemplate]'
            },] },
];
/** @nocollapse */
ItemTemplateDirective.ctorParameters = () => [
    { type: TemplateRef }
];

/**
 * Renders the header content of the ListView. To define the header template, nest an `<ng-template>` tag
 * with the `kendoListViewHeaderTemplate` directive inside the component tag.
 */
class HeaderTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
}
HeaderTemplateDirective.decorators = [
    { type: Directive, args: [{
                selector: '[kendoListViewHeaderTemplate]'
            },] },
];
/** @nocollapse */
HeaderTemplateDirective.ctorParameters = () => [
    { type: TemplateRef }
];

/**
 * Renders the footer content of the ListView. To define the footer template, nest an `<ng-template>` tag
 * with the `kendoListViewFooterTemplate` directive inside the component tag.
 */
class FooterTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
}
FooterTemplateDirective.decorators = [
    { type: Directive, args: [{
                selector: '[kendoListViewFooterTemplate]'
            },] },
];
/** @nocollapse */
FooterTemplateDirective.ctorParameters = () => [
    { type: TemplateRef }
];

/**
 * Overrides the default loader content of the ListView. To define the loader template, nest an `<ng-template>` tag
 * with the `kendoListViewLoaderTemplate` directive inside the component tag.
 */
class LoaderTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
}
LoaderTemplateDirective.decorators = [
    { type: Directive, args: [{
                selector: '[kendoListViewLoaderTemplate]'
            },] },
];
/** @nocollapse */
LoaderTemplateDirective.ctorParameters = () => [
    { type: TemplateRef }
];

const DEFAULT_PAGER_SETTINGS = {
    position: 'bottom',
    buttonCount: 5,
    info: true,
    previousNext: true,
    type: 'numeric',
    pageSizeValues: [5, 10, 20]
};
/**
 * Represents the Kendo UI ListView component for Angular.
 */
class ListViewComponent {
    constructor(ngZone, element, renderer, navigationService) {
        this.ngZone = ngZone;
        this.element = element;
        this.renderer = renderer;
        this.navigationService = navigationService;
        /**
         * @hidden
         */
        this.className = true;
        /**
         * Specifies if the loading indicator of the ListView will be displayed.
         */
        this.loading = false;
        /**
         * Specifies the content container `role` attribute.
         * By default, the container `role` is set to `listbox`.
         */
        this.containerRole = 'listbox';
        /**
         * Specifies the list item `role` attribute.
         * By default, the list item `role` is set to `option`.
         */
        this.listItemRole = 'option';
        /**
         * Defines the number of records to be skipped by the pager.
         */
        this.skip = 0;
        /**
         * Fires when the user scrolls to the last record on the page.
         * You have to handle the event yourself and page the data.
         */
        this.scrollBottom = new EventEmitter();
        /**
         * Fires when the page or the page size of the ListView is changed.
         * You have to handle the event yourself and page the data.
         */
        this.pageChange = new EventEmitter();
        /**
         * Fires when the page size of the ListView is changed. This event can be prevented (`$event.preventDefault()`).
         * If not prevented, the `pageChange` event will be fired subsequently.
         */
        this.pageSizeChange = new EventEmitter();
        this._navigable = false;
        /**
         * @hidden
         */
        this.handleContentScroll = () => {
            const THRESHOLD = 2;
            const { scrollHeight, scrollTop, clientHeight } = this.contentContainer.nativeElement;
            const atBottom = scrollHeight - clientHeight - scrollTop <= THRESHOLD;
            if (atBottom) {
                this.ngZone.run(() => {
                    const event = { sender: this };
                    this.scrollBottom.emit(event);
                });
            }
        };
    }
    /**
     * Specifies whether the keyboard navigation is activated.
     * By default, the navigation is disabled.
     */
    set navigable(navigable) {
        if (!navigable && isPresent(this.removeNavigationListeners)) {
            this.removeNavigationListeners();
            this.removeNavigationListeners = null;
            this.navigationService.isEnabled = false;
        }
        else if (navigable && !isPresent(this.removeNavigationListeners)) {
            this.addNavigationListeners();
            this.navigationService.isEnabled = true;
        }
        this._navigable = navigable;
    }
    get navigable() {
        return this._navigable;
    }
    /**
     * Configures whether the ListView will render a pager.
     * Providing a boolean value will render a pager with default settings.
     */
    set pageable(pageable) {
        this._pageable = pageable;
        this.pagerSettings = pageable ? Object.assign({}, DEFAULT_PAGER_SETTINGS, pageable) : null;
    }
    get pageable() {
        return this._pageable;
    }
    /**
     * @hidden
     *
     * Gets the data items passed to the ListView.
     * If a ListViewDataResult is passed, the data value is used. If an array is passed - it's directly used.
     */
    get items() {
        if (!isPresent(this.data)) {
            return [];
        }
        return Array.isArray(this.data) ? this.data : this.data.data;
    }
    /**
     * @hidden
     *
     * Gets the total number of records passed to the ListView.
     * If a ListViewDataResult is passed, the total value is used. If an array is passed - its length is used.
     */
    get total() {
        if (!isPresent(this.data)) {
            return 0;
        }
        return Array.isArray(this.data) ? this.data.length : this.data.total;
    }
    /**
     * Gets the current active item index.
     * Returns `null` when the keyboard navigation is disabled.
     */
    get activeIndex() {
        return this.navigationService.activeIndex;
    }
    ngOnChanges(changes) {
        if (isChanged('height', changes, false)) {
            this.renderer.setStyle(this.element.nativeElement, 'height', `${this.height}px`);
        }
    }
    /**
     * Focuses the item at the specified index:
     * - If no index is specified, the current active index will be focused.
     * - If the passed value is below `0`, the first item recieves focus.
     * - If the passed value is above the last available index, the last item recieves focus.
     *
     * > The index param is based on the logical structure of the ListView and does not correspond to the data item index -
     * > i.e. the index `0` corresponds to the first rendered list item. Paging is not taken into account.
     * > Also, for the focusing to work, the `navigable` prop must first be set to `true`.
     */
    focus(index) {
        const totalRenderedItems = this.listViewItems.length;
        this.navigationService.focusIndex(index, totalRenderedItems);
    }
    /**
     * @hidden
     */
    handlePageChange(event) {
        this.scrollToContainerTop();
        const firstIndex = 0;
        this.navigationService.setActiveIndex(firstIndex);
        this.pageChange.emit(event);
    }
    scrollToContainerTop() {
        const container = this.contentContainer.nativeElement;
        container.scrollTop = 0;
        container.scrollLeft = 0;
    }
    addNavigationListeners() {
        this.ngZone.runOutsideAngular(() => {
            const removeKeydownListener = this.renderer.listen(this.contentContainer.nativeElement, 'keydown', event => this.navigationService.handleKeyDown(event, this.listViewItems.length));
            const removeFocusInListener = this.renderer.listen(this.contentContainer.nativeElement, 'focusin', event => this.navigationService.handleFocusIn(event));
            const removeFocusOutListener = this.renderer.listen(this.contentContainer.nativeElement, 'focusout', event => this.navigationService.handleFocusOut(event));
            this.removeNavigationListeners = () => {
                removeKeydownListener();
                removeFocusInListener();
                removeFocusOutListener();
            };
        });
    }
}
ListViewComponent.decorators = [
    { type: Component, args: [{
                changeDetection: ChangeDetectionStrategy.OnPush,
                exportAs: 'kendoListView',
                selector: 'kendo-listview',
                providers: [
                    NavigationService
                ],
                template: `
        <!-- top pager -->
        <ng-template
            *ngIf="pagerSettings?.position !== 'bottom'"
            [ngTemplateOutlet]="pagerTemplate"
            [ngTemplateOutletContext]="{ pagerClass: 'k-listview-pager k-listview-pager-top' }"
        >
        </ng-template>

        <!-- header -->
        <div
            *ngIf="headerTemplate"
            class="k-listview-header"
        >
            <ng-template
                [ngTemplateOutlet]="headerTemplate?.templateRef"
            >
            </ng-template>
        </div>

        <!-- content -->
        <div
            #contentContainer
            class="k-listview-content"
            [ngClass]="containerClass"
            [ngStyle]="containerStyle"
            [kendoEventsOutsideAngular]="{
                scroll: handleContentScroll
            }"
            [attr.role]="containerRole"
            [attr.aria-label]="containerLabel"
            [attr.aria-setsize]="total"
        >
            <!-- items -->
            <div
                *ngFor="let dataItem of items; let index = index; let first = first; let last = last;"
                class="k-listview-item"
                [attr.role]="listItemRole"
                [attr.aria-posinset]="skip + index + 1"
                kendoListViewNavigableItem
                [index]="index"
                [attr.data-kendo-listview-item-index]="index"
                [ngClass]="itemClass"
                [ngStyle]="itemStyle"
            >
                <ng-template
                    [templateContext]="{
                        templateRef: itemTemplate?.templateRef,
                        $implicit: dataItem,
                        dataItem: dataItem,
                        index: index,
                        isFirst: first,
                        isLast: last
                    }"
                >
                </ng-template>
            </div>

            <!-- loading indicator -->
            <div
                *ngIf="loading && !loaderTemplate"
                class="k-loading-mask"
            >
                <!-- TODO: the k-loading-text is hidden with css but read by readers - review when implementing accessibility + possible localization case -->
                <span class="k-loading-text">Loading</span>
                <div class="k-loading-image"></div>
                <div class="k-loading-color"></div>
            </div>
            <ng-template
                *ngIf="loading && loaderTemplate"
                [ngTemplateOutlet]="loaderTemplate.templateRef"
            >
            </ng-template>
        </div>

        <!-- footer -->
        <div
            *ngIf="footerTemplate"
            class="k-listview-footer"
        >
            <ng-template
                [ngTemplateOutlet]="footerTemplate?.templateRef"
            >
            </ng-template>
        </div>

        <!-- bottom pager -->
        <ng-template
            *ngIf="pagerSettings?.position !== 'top'"
            [ngTemplateOutlet]="pagerTemplate"
            [ngTemplateOutletContext]="{ pagerClass: 'k-listview-pager' }"
        >
        </ng-template>

        <!-- pager template -->
        <ng-template #pagerTemplate let-pagerClass="pagerClass">
            <kendo-datapager
                *ngIf="pageable"
                [class]="pagerClass"
                [total]="total"
                [pageSize]="pageSize"
                [skip]="skip"
                [buttonCount]="pagerSettings.buttonCount"
                [info]="pagerSettings.info"
                [previousNext]="pagerSettings.previousNext"
                [type]="pagerSettings.type"
                [pageSizeValues]="pagerSettings.pageSizeValues"
                (pageChange)="handlePageChange($event)"
                (pageSizeChange)="pageSizeChange.emit($event)"
            >
            </kendo-datapager>
        </ng-template>
    `
            },] },
];
/** @nocollapse */
ListViewComponent.ctorParameters = () => [
    { type: NgZone },
    { type: ElementRef },
    { type: Renderer2 },
    { type: NavigationService }
];
ListViewComponent.propDecorators = {
    className: [{ type: HostBinding, args: ['class.k-widget',] }, { type: HostBinding, args: ['class.k-listview',] }, { type: HostBinding, args: ['class.k-d-flex',] }],
    itemTemplate: [{ type: ContentChild, args: [ItemTemplateDirective,] }],
    headerTemplate: [{ type: ContentChild, args: [HeaderTemplateDirective,] }],
    footerTemplate: [{ type: ContentChild, args: [FooterTemplateDirective,] }],
    loaderTemplate: [{ type: ContentChild, args: [LoaderTemplateDirective,] }],
    contentContainer: [{ type: ViewChild, args: ['contentContainer', { static: true },] }],
    listViewItems: [{ type: ViewChildren, args: [ListViewNavigableItemDirective, { static: true },] }],
    data: [{ type: Input }],
    loading: [{ type: Input }],
    containerStyle: [{ type: Input }],
    itemStyle: [{ type: Input }],
    containerClass: [{ type: Input }],
    itemClass: [{ type: Input }],
    containerLabel: [{ type: Input }],
    containerRole: [{ type: Input }],
    listItemRole: [{ type: Input }],
    navigable: [{ type: Input }],
    pageSize: [{ type: Input }],
    skip: [{ type: Input }],
    pageable: [{ type: Input }],
    height: [{ type: Input }],
    scrollBottom: [{ type: Output }],
    pageChange: [{ type: Output }],
    pageSizeChange: [{ type: Output }]
};

/**
 * A directive which encapsulates the in-memory handling of paging.
 */
class DataBindingDirective {
    constructor(listView) {
        this.listView = listView;
        this.subscriptions = new Subscription();
    }
    /**
     * The array of data which will be used to populate the ListView.
     */
    set data(data) {
        this._data = data || [];
        this.updateListViewData();
    }
    get data() {
        return this._data;
    }
    ngOnInit() {
        this.subscriptions.add(this.listView.pageChange.subscribe(this.handlePageChange.bind(this)));
        this.subscriptions.add(this.listView.pageSizeChange.subscribe(this.handlePageSizeChange.bind(this)));
        this.updateListViewData();
    }
    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
    handlePageChange(event) {
        this.listView.skip = event.skip;
        this.listView.pageSize = event.take;
        this.updateListViewData();
    }
    handlePageSizeChange(event) {
        this.listView.pageSize = Number(event.newPageSize);
    }
    updateListViewData() {
        const from = this.listView.skip || 0;
        const to = from + (this.listView.pageSize || this.data.length);
        this.listView.data = {
            data: this.data.slice(from, to),
            total: this.data.length
        };
    }
}
DataBindingDirective.decorators = [
    { type: Directive, args: [{
                selector: '[kendoListViewBinding]'
            },] },
];
/** @nocollapse */
DataBindingDirective.ctorParameters = () => [
    { type: ListViewComponent }
];
DataBindingDirective.propDecorators = {
    data: [{ type: Input, args: ['kendoListViewBinding',] }]
};

/**
 * The event emitted when the page size is changed through the pager.
 */
class PageSizeChangeEvent$1 extends PageSizeChangeEvent {
}

/**
 * @hidden
 */
class TemplateContextDirective {
    constructor(viewContainerRef) {
        this.viewContainerRef = viewContainerRef;
    }
    set templateContext(context) {
        if (this.insertedViewRef) {
            this.viewContainerRef.remove(this.viewContainerRef.indexOf(this.insertedViewRef));
            this.insertedViewRef = undefined;
        }
        if (context.templateRef) {
            this.insertedViewRef = this.viewContainerRef.createEmbeddedView(context.templateRef, context);
        }
    }
}
TemplateContextDirective.decorators = [
    { type: Directive, args: [{
                selector: '[templateContext]' // tslint:disable-line
            },] },
];
/** @nocollapse */
TemplateContextDirective.ctorParameters = () => [
    { type: ViewContainerRef }
];
TemplateContextDirective.propDecorators = {
    templateContext: [{ type: Input }]
};

const TEMPLATE_DIRECTIVES = [
    ItemTemplateDirective,
    HeaderTemplateDirective,
    FooterTemplateDirective,
    TemplateContextDirective,
    LoaderTemplateDirective
];
const BINDING_DIRECTIVES = [
    DataBindingDirective
];
/**
 * The exported package module.
 *
 * The package exports:
 * - `ListViewComponent`&mdash;The ListView component class.
 * - `ItemTemplateDirective`&mdash;The item template directive.
 * - `HeaderTemplateDirective`&mdash;The header template directive.
 * - `FooterTemplateDirective`&mdash;The footer template directive.
 * - `LoaderTemplateDirective`&mdash;The loeader template directive.
 */
class ListViewModule {
}
ListViewModule.decorators = [
    { type: NgModule, args: [{
                declarations: [
                    TEMPLATE_DIRECTIVES,
                    BINDING_DIRECTIVES,
                    ListViewComponent,
                    ListViewNavigableItemDirective
                ],
                exports: [
                    ListViewComponent,
                    TEMPLATE_DIRECTIVES,
                    BINDING_DIRECTIVES,
                    CommonModule,
                    EventsModule
                ],
                imports: [
                    CommonModule,
                    PagerModule,
                    EventsModule
                ]
            },] },
];

/**
 * Generated bundle index. Do not edit.
 */

export { ListViewNavigableItemDirective, NavigationService, TemplateContextDirective, ListViewComponent, ItemTemplateDirective, HeaderTemplateDirective, FooterTemplateDirective, LoaderTemplateDirective, DataBindingDirective, PageSizeChangeEvent$1 as PageSizeChangeEvent, ListViewModule };
